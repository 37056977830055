import { Toaster } from 'react-hot-toast';
import { ReactNode, CSSProperties } from 'react';

import Header from '/components/layout/Header';
import Footer from '/components/layout/Footer';
import FrontCustomSiteControlls from '/components/FrontCustomSiteControlls';
import { AdminBar } from '/front';
import Menu from '/front/types/frontMenuType';
import { JobTemplate } from '/types/jobTemplateTypes';

interface Props {
    simplified?: boolean;
    style?: CSSProperties;
    menus?: Menu[];
    children?: ReactNode;
    isCmsPage?: boolean;
    jobType?: JobTemplate['typeKey'];
}

export default function Layout({
    simplified = false,
    style = {},
    menus = [],
    children,
    isCmsPage = false,
    jobType,
}: Props) {
    return (
        <>
            <div style={{ flex: 1, ...style }}>
                <Header
                    simplified={simplified}
                    menus={menus}
                    jobType={jobType}
                />
                {children}
                <Toaster />
            </div>
            {!simplified && <Footer menus={menus} />}
            <AdminBar
                customSiteControlls={<FrontCustomSiteControlls />}
                isCmsPage={isCmsPage}
            />
        </>
    );
}
