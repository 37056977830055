import { NextRouter } from 'next/router';
import { UserType } from './../../../types/userTypes';
import { MenuItem, RoleMenus } from './Menu.types';
import { pathNameIs, stringStartsWithOneOf, userIs } from '/lib/helpers';
import Menu from '/front/types/frontMenuType';
import { UserRole } from '/types/userTypes';

export const stripDomain = (url: string) => url.replace(/^.*\/\/[^\/]+/, '');

export const cmsMenusToLinks = (type: string, menus: Menu[] = []) => {
    if (!Array.isArray(menus)) return [];
    return (menus.find((m) => m._key === type)?.items || []).map((i) => ({
        href: i.type === 'page' ? stripDomain(i.url) : i.url,
        title: i.label,
        cms: true,
    }));
};

export const isActiveMenuItem = (item: MenuItem, router: NextRouter) =>
    item.activeOn
        ? item.activeOn.includes(router.pathname)
        : item.cms
        ? router.asPath === item.href
        : router.asPath.includes('/' + (item.href.split('/')[1] || '-'));

export const getRoleMenus = (
    user: UserType | undefined,
    cmsMenus: Menu[]
): RoleMenus => {
    const contractorUuid = user?.contractor?.uuid;
    return {
        unAuthenticated: cmsMenusToLinks('top-menu', cmsMenus),
        [UserRole.CUSTOMER]: cmsMenusToLinks('top-menu-customer', cmsMenus),
        [UserRole.SALES_PARTNER_SELLER]: cmsMenusToLinks(
            'top-menu-seller',
            cmsMenus
        ),
        [UserRole.CASEWORKER]: [
            { href: '/prosjekter', title: 'Prosjekter' },
            { href: '/reklamasjon/liste', title: 'Reklamasjon' },
            { href: '/varsler', title: 'Varsler' },
            { href: '/kunder', title: 'Kunder' },
        ],
        [UserRole.STORE_EMPLOYEE]: [
            { href: '/prosjekter', title: 'Prosjekter' },
            { href: '/reklamasjon/liste', title: 'Reklamasjon' },
            { href: '/kunder', title: 'Kunder' },
        ],
        [UserRole.CUSTOMER_SERVICE]: [
            { href: '/prosjekter', title: 'Prosjekter' },
            { href: '/reklamasjon/liste', title: 'Reklamasjon' },
            { href: '/varsler', title: 'Varsler' },
            { href: '/kunder', title: 'Kunder' },
        ],
        [UserRole.ELECTRICIAN]: [
            {
                href: `/montor/${user?.uuid}/kalender`,
                title: 'Kalender',
                activeOn: ['/electrician/[electricianUuid]/calendar'],
            },
            { href: '/prosjekter', title: 'Prosjekter' },
            { href: '/reklamasjon/liste', title: 'Reklamasjon' },
            {
                href: `/montor/${user?.uuid}/tilgjengelighet`,
                title: 'Tilgjengelighet',
                activeOn: ['/electrician/[electricianUuid]/availability'],
            },
        ],
        [UserRole.GLOBAL_ADMIN]: [
            { href: '/prosjekter', title: 'Prosjekter' },
            { href: '/reklamasjon/liste', title: 'Reklamasjon' },
            { href: '/varsler', title: 'Varsler' },
            { href: '/kunder', title: 'Kunder' },
            {
                href: '/jobbmaler/liste',
                title: 'Jobbmaler',
                class: 'desktop-only',
                activeOn: ['/definition', '/definition/list'],
            },
            {
                href: '/produkter/liste',
                title: 'Produkter',
                class: 'desktop-only',
                activeOn: ['/product', '/product/list'],
            },
            {
                href: '/nokkeltall',
                title: 'Nøkkeltall',
                activeOn: ['/analytics'],
            },
            {
                href: '/partnere',
                title: 'Partnere',
                children: [
                    {
                        href: '/selgere',
                        title: 'Salgspartnere',
                        activeOn: [
                            '/sales-partner',
                            '/sales-partner/bonus',
                            '/sales-partner/bonus-periods',
                        ],
                    },
                    {
                        href: '/partnere',
                        title: 'Montører',
                        activeOn: ['/analytics/partners'],
                    },
                ],
                activeOn: [
                    '/analytics/partners',
                    '/sales-partner',
                    '/sales-partner/bonus',
                    '/sales-partner/bonus-periods',
                ],
            },
        ],
        [UserRole.CONTRACTOR_ADMIN]: [
            {
                href: `/partner/${contractorUuid}/kalender`,
                title: `Kalender`,
                activeOn: [`/contractor/[contractorUuid]/calendar`],
            },
            { href: '/prosjekter', title: 'Prosjekter' },
            { href: '/reklamasjon/liste', title: 'Reklamasjon' },
            {
                href: `/partner/${contractorUuid}/dinemontorer`,
                title: `Montører`,
                activeOn: [
                    `/contractor/[contractorUuid]/electricians`,
                    `/contractor/[contractorUuid]/electricians/new`,
                    `/contractor/[contractorUuid]/electricians/single`,
                    `/contractor/[contractorUuid]/electricians/[electricianUuid]`,
                ],
            },
        ],
    };
};

export const getMenuForRole = (
    roleMenus: RoleMenus,
    router: NextRouter,
    user: UserType | undefined
) =>
    (() => {
        if (!user) return roleMenus.unAuthenticated;
        const isBackend = pathNameIs(router.pathname).backend;

        if (
            userIs(user, [UserRole.SALES_PARTNER_SELLER]) &&
            stringStartsWithOneOf(router.asPath, [
                '/selgerside',
                '/nettbutikk',
                '/bestilling',
                '/videobefaring',
                '/instillinger',
                '/selger',
            ])
        )
            return roleMenus[UserRole.SALES_PARTNER_SELLER];

        if (isBackend) {
            if (userIs(user, UserRole.GLOBAL_ADMIN))
                return roleMenus[UserRole.GLOBAL_ADMIN];
            if (userIs(user, UserRole.CASEWORKER))
                return roleMenus[UserRole.CASEWORKER];
            if (userIs(user, UserRole.CONTRACTOR_ADMIN))
                return roleMenus[UserRole.CONTRACTOR_ADMIN];
            if (userIs(user, UserRole.ELECTRICIAN))
                return roleMenus[UserRole.ELECTRICIAN];
            if (userIs(user, UserRole.STORE_EMPLOYEE))
                return roleMenus[UserRole.STORE_EMPLOYEE];
            if (userIs(user, UserRole.CUSTOMER_SERVICE))
                return roleMenus[UserRole.CUSTOMER_SERVICE];
            if (userIs(user, UserRole.CUSTOMER))
                return roleMenus[UserRole.CUSTOMER];
            return roleMenus.unAuthenticated;
        }

        return roleMenus.unAuthenticated;
    })() ?? [];
